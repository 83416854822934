<!-- 新闻详情 -->
<template>
  <div class="lookparticulars">
      <div class="nav">
          <H5Carousel/>
      </div>
      <div class="contents">
          <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" :key="index" @click="gopage(index)" :style="{background:item.background,color:item.color}">
                        {{item.title}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
            <div class="content">
               <p class="contenttitle">{{ lookparticulars.title }}</p>
               <p class="releaseTime">
                 <!-- <span>作者：烟台八角湾国际会展中心</span> -->
                <br>
                 <span>发布时间：{{ lookparticulars.createTime }}</span>
               </p>
               <p class="text" v-html="lookparticulars.newsContent"></p>
             </div>
      </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
export default {
    components:{
        H5Carousel,H5Bottom
    },
    data() {
        return {
             nav:[
                {title:"新闻动态",background: "#197cc3",color: "#fff"},
                {title:"精彩瞬间",background: "#fff",color: "#000"},
                {title:"展会排期",background: "#fff",color: "#000"},
                {title:"通知公告",background: "#fff",color: "#000"},
            ],  
            lookparticulars: "",  
            activeNames: ['0'], 
            navtitle:"通知公告" ,
            newslist:[],
            total:null,
            pageNum:1,
            pageSize:5, 
        }
    },
    created() {
        let data = this.$route.query.id;
         console.log(data);
         this.$api.lookparticulars(data, (res) => {
           if (res.data.code == 200) {
             console.log(res.data.data);
             this.lookparticulars = res.data.data;
           }
         });
        if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.navtitle = '新闻动态'
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff' 
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    return item
                })
             this.nav = navlist
         }
    },
    methods:{
        gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                // console.log(item,index);
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
               this.navtitle = '新闻动态'
               this.$router.replace({path:'/TradeShowTrends',query:{path:`新闻动态`}})
               this.activeNames = ["0"]
           }else if(val == 1){
               this.navtitle = '精彩瞬间'
               this.$router.replace({path:'/TradeShowTrends',query:{path:`精彩瞬间`}})
               this.activeNames = ["1"]
           }else if(val == 2){
               this.navtitle = '展会排期'
               this.$router.replace({path:'/TradeShowTrends',query:{path:`展会排期`}})
               this.activeNames = ["2"]
           }else if(val == 3){
               this.navtitle = '通知公告'
               this.$router.replace({path:'/TradeShowTrends',query:{path:`通知公告`}})
               this.activeNames = ["3"]
           }
        }
    }
}
</script>

<style scoped>
    .lookparticulars{
        width: 100%;
        height: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
        border-bottom: 0.01rem solid #999;
        margin-bottom: 0.1rem;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .contents{
        width: 100%;
    }
    .content{
        padding: 0.2rem;
        background-color: #FAFAFA;
        margin-bottom: 0.3rem;
        margin-top: 0.2rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        /* text-align: center */
    }
    .contenttitle{
        font-size: 0.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0.3rem;
    }
    .releaseTime {
      font-size: 0.1rem;
      color: #666;
      line-height: 0.2rem;
      margin-top: 0.2rem;
    }
    .text {
        margin-top: 0.2rem;
      font-size: 0.17rem;
      line-height: 0.3rem;
      text-indent: 0.3rem;
    }
    .text >>>.ql-align-center{
  text-align: center;
  text-indent:0;
}
.text >>> strong{
  display: block;
  text-align: center;
  text-indent:0;
}
.text >>> img {
  display: block;
  margin: 0 auto;
  text-indent:0;
}
    .bot{
        margin-top: 0.5rem
    }
</style>